import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { H3, Ol, Paragraph, TextContainer, Ul } from "../../lib/src";
import { DocPageMeta, HeaderSection, ContentSection, Code, Header } from "../docs";
import { Accordion } from "../docs/docs-accordion";
import { CodeBlock } from "../docs/docs-code";

export const Meta: DocPageMeta = {
	category: "Designing",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="General FAQ for designers"
			subTitle="Answers to general questions about Fancy and it processes."
		/>
		<ContentSection>
			<TextContainer>
				<Header.H2>General</Header.H2>
				<Accordion headingLevel={H3}>
					<Accordion.Section title="What's Fancy's source of truth?">
						<Paragraph>
							In short, this styleguide. For a more detailed answer, please read on.
						</Paragraph>
						<Paragraph>
							Fancy’s components and guidelines have been documented on various websites and
							Confluence pages. That has understandably led to confusion. We hope to clear things up
							here.
						</Paragraph>
						<Paragraph>
							When it comes to Fancy’s guidelines, this styleguide is the only source of truth.
							We’re in the process of moving all guidelines documented elsewhere to here. As a
							result, some guidelines may be missing. Please contact us if you can’t find the
							guideline you’re interested in.
						</Paragraph>
						<Paragraph>
							When it comes to Fancy’s components, Fancy’s code is the ultimate source of truth.
							This styleguide is the most accurate reflection of that truth. The{" "}
							<GatsbyLink to="/Designing/figma-FAQ">Fancy UI kit for Figma</GatsbyLink> is less
							reliable than the styleguide. So, if you want to know how a component looks and
							functions, you’re in the right place.
						</Paragraph>
					</Accordion.Section>
					<Accordion.Section title="What's FancyPFG?">
						<Paragraph>
							<Code>FancyPFG</Code> is a folder in PFG, which contains code written in TypeScript
							and React. The code is split up into subfolders, some of which are product specific.
							There's also a folder called <Code>Shared</Code>. This folder is effectively the
							previous version of Fancy.
						</Paragraph>
						<Paragraph>
							Products built outside of PFG, such as Performance and Data Privacy, cannot use the
							code in the <Code>Shared</Code> folder. As a result, the decision was made to move the
							folder's contents to an external repository, accessible by all. That new repository is
							simply called <Code>Fancy</Code>. The components documented in this styleguide all
							originate from the <Code>Fancy</Code> repository.
						</Paragraph>
					</Accordion.Section>
					<Accordion.Section title="How do I get in touch with the Fancy team?">
						<Ul
							items={[
								<>
									For design related questions, please use the{" "}
									<a href="https://siteimprove.slack.com/archives/CAVM2RC4F">ux_fancy</a> channel.
								</>,
								<>
									If your question is more technical, please use the{" "}
									<a href="https://siteimprove.slack.com/archives/CCH0J3U4E">dev_fancy</a> channel.
								</>,
								<>
									If your question concerns accessibility, please use the{" "}
									<a href="https://siteimprove.slack.com/archives/C02B3J1LL6S">a11y_fancy</a>{" "}
									channel.
								</>,
							]}
						></Ul>
					</Accordion.Section>
					<Accordion.Section title="Is it okay for me to frequently contact the Fancy Team?">
						<Paragraph>
							Yes, absolutely. We may seem busy, but supporting users of Fancy is our top priority.
						</Paragraph>
					</Accordion.Section>
				</Accordion>
				<Header.H2>Components</Header.H2>
				<Accordion headingLevel={H3}>
					<Accordion.Section title="How do I check whether something in the platform is a Fancy component?">
						<Paragraph>One option is to use the “Internal tools” bar:</Paragraph>
						<Ol
							items={[
								"To turn it on, click the button with the wrench icon in the top right corner.",
								"Click on “State”, check “Highlight Fancy components”, and click “Store”.",
								"If the UI element now has an orange border, it’s a Fancy component.",
							]}
						></Ol>
						<Paragraph>
							Alternatively, you can use your browser's developer tools. This will also give you the
							name of the component. Here's how to do it in Chrome and Firefox on macOS:
						</Paragraph>
						<Ol
							items={[
								<>
									Press <Code>⌥</Code> (option) + <Code>⌘</Code> (command) + <Code>C</Code>.
								</>,
								"Click on the UI element in question.",
								<>
									If the element’s opening tag contains a <Code>data-component</Code> attribute,
									it’s a Fancy component. The value of the <Code>data-component</Code> tag will be
									the component's name. For instance:
									<CodeBlock language="html">{`<section data-component="card" ...>...</section>`}</CodeBlock>
								</>,
							]}
						></Ol>
					</Accordion.Section>
					<Accordion.Section title="Can all Fancy components be found in this styleguide?">
						<Paragraph>Practically speaking, yes.</Paragraph>
						<Paragraph>
							Some low-level components are missing, though. This is because they're used to build
							other components and are not meant to be used by product teams. There are also a few
							components that remain to be moved over from <Code>FancyPFG</Code>. However, they'll
							soon be available. In any case, if you can't find the component you're looking for,
							please reach out to us through the{" "}
							<a href="https://siteimprove.slack.com/archives/CAVM2RC4F">ux_fancy</a> channel.
						</Paragraph>
						<Paragraph></Paragraph>
					</Accordion.Section>
					<Accordion.Section title="What’s the difference between Lib and Lab components?">
						<Paragraph>
							Lib and Lab components are both part of the <Code>Fancy</Code> repository. Lab
							components are components that don’t satisfy our{" "}
							<GatsbyLink to="/Developing/adding-components">Definition of Done</GatsbyLink> (DoD).
							The DoD is a list of requirements related to development, UX, accessibility, process,
							and documentation. New components and components that have been migrated from{" "}
							<Code>FancyPFG</Code> start out in Lab.
						</Paragraph>
						<Paragraph>
							When a component satisfies all DoD requirements, it's moved from Lab to Lib. However,
							this doesn’t mean the component is perfect or won’t be changed in the future.
						</Paragraph>
					</Accordion.Section>
					<Accordion.Section title="Is it okay to use Lab components in my designs?">
						<Paragraph>
							Yes. However, since Lab components are a work-in-progress, there are some things to
							keep in mind:
						</Paragraph>
						<Ul
							items={[
								"A Lab component may be changed significantly in the process of making it ready for Lib.",
								"A Lab component may not yet live up to our standards when it comes to UX and accessibility.",
								"A Lab component may lack proper documentation and guidelines.",
							]}
						/>
						<Paragraph>
							Please reach out to us, if you'd like to know more about the component, such as known
							issues, planned changes, or (undocumented) guidelines.
						</Paragraph>
					</Accordion.Section>
					<Accordion.Section title="How do I find out whether a component I need it exists in Fancy?">
						<Paragraph>
							Fancy’s ultimate source of truth is its code. However, for a designer, the best place
							to look is here in the styleguide. The{" "}
							<GatsbyLink to="/Designing/figma-FAQ">Fancy UI kit for Figma</GatsbyLink> is also an
							option, but it will never be as reliable or accurate as the styleguide.
						</Paragraph>
					</Accordion.Section>
					<Accordion.Section title="I can't find the component I'm looking for. What do I do?">
						<Paragraph>
							Contact us through the{" "}
							<a href="https://siteimprove.slack.com/archives/CAVM2RC4F">ux_fancy</a> channel.
							There’s a chance the component exists, but hasn’t been moved from{" "}
							<Code>FancyPFG</Code> to the new <Code>Fancy</Code> repository. If the component
							doesn’t exist anywhere, we’ll discuss whether it adds shared value. Shared value means
							the component would also benefit other product teams and use cases.
						</Paragraph>
						<Paragraph>
							If shared value is established, we’ll discuss how your team and the Fancy team can
							collaborate on implementing the component. If shared value is not established, we’ll
							help you figure out whether existing Fancy components can be used to build your
							feature. If not, we’ll provide you with input for creating a custom UI element.
						</Paragraph>
					</Accordion.Section>
					<Accordion.Section title="A component doesn't meet my requirements. What do I do?">
						<Paragraph>
							Contact us through the{" "}
							<a href="https://siteimprove.slack.com/archives/CAVM2RC4F">ux_fancy</a> channel. We’ll
							discuss whether changing the component to satisfy your requirements would add shared
							value. Shared value means the change would also benefit other product teams and use
							cases.
						</Paragraph>
						<Paragraph>
							If shared value is established, we’ll discuss how your team and the Fancy team can
							collaborate on changing the component. If shared value is not established, we’ll help
							you figure out whether existing Fancy components can be used to build your feature. If
							not, we’ll provide you and your team with input for creating a custom version of the
							component.
						</Paragraph>
					</Accordion.Section>
				</Accordion>
				<Header.H2>Process</Header.H2>
				<Accordion headingLevel={H3}>
					<Accordion.Section title="During a project, when should I check in with Fancy?">
						<Paragraph>Basically any time you would like our help, input or feedback.</Paragraph>
						<Paragraph>
							However, we do recommend a check in before handing off your designs to developers.
							This is mainly because many Fancy components are in Lab. That means they may not live
							up to our standards when it comes to UX, accessibility, and documentation.
						</Paragraph>
						<Paragraph>
							By checking in with us, we can inform you about any known issues with the components
							you wish to use. We can also discuss how our teams can collaborate to fix critical
							issues. Lastly, we can give you feedback on component usage, accessibility, and other
							design considerations. The aim of all of this is to make the implementation of your
							designs as smooth as possible.
						</Paragraph>
					</Accordion.Section>
					<Accordion.Section title="What kind of support can I expect from Fancy?">
						<Paragraph>
							You can expect our help, input or feedback on any topic related to Fancy. Here are
							some examples:
						</Paragraph>
						<Ul
							items={[
								"Picking the right Fancy components for your use case.",
								"Using Fancy components in a way that aligns with our UX, accessibility, and writing guidelines.",
								"Figuring out what to do when the component you need is not part of Fancy.",
								"Figuring out what to do when a Fancy component doesn’t look or function the way you want it to.",
								"Using our design tokens to design UI elements that visually align with Fancy.",
							]}
						></Ul>
					</Accordion.Section>
					<Accordion.Section title="How do I make sure my designs are accessible?">
						<Paragraph>
							Our{" "}
							<GatsbyLink to="/Designing/accessibility-checklist">
								accessibility checklist for designers
							</GatsbyLink>{" "}
							is a good place to start. It’s a work in progress, though. So, if you have any further
							questions, please reach out to Fancy’s accessibility experts through the{" "}
							<a href="https://siteimprove.slack.com/archives/C02B3J1LL6S">a11y_fancy</a> channel.
						</Paragraph>
					</Accordion.Section>
				</Accordion>
			</TextContainer>
		</ContentSection>
	</>
);
